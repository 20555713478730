import Layout from '@components/Layout';
import AppAdvertisement from '@features/AppAdvertisement';

const AppAdvertisementPage = () => {
  return (
    <Layout center>
      <AppAdvertisement />
    </Layout>
  );
};

export default AppAdvertisementPage;
