import CustomText from '@components/shared/CustomText';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import styles from './CustomButton.module.scss';

const CustomButton = ({
  text,
  onClick,
  disabled,
  secondary,
  customStyle,
  transparent,
  maxWidth,
  icon,
  small,
  ...props
}) => {
  return (
    <button
      disabled={disabled}
      className={clsx(styles.button, {
        [styles.secondary]: !!secondary,
        [styles.primary]: !secondary,
        [styles.transparent]: !!transparent,
        [styles.disabled]: !!disabled,
        [styles.disabledTransparent]: !!disabled && !!transparent,
        [customStyle]: !!customStyle,
        [styles.smallButton]: small,
      })}
      style={{ maxWidth: maxWidth }}
      onClick={onClick}
      {...props}
    >
      {icon && (
        <div className={clsx(styles.icon, small && styles.smallIcon)}>
          {icon}
        </div>
      )}
      <CustomText bold customStyle={clsx(small && styles.smallText)}>
        {text}
      </CustomText>
    </button>
  );
};

CustomButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  transparent: PropTypes.bool,
  secondary: PropTypes.bool,
  maxWidth: PropTypes.number,
  icon: PropTypes.node,
  small: PropTypes.bool,
  customStyle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default CustomButton;
