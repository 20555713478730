import clsx from 'clsx';
import PropTypes from 'prop-types';

import { DM_Sans } from '@next/font/google';

import styles from './CustomText.module.scss';

const font = DM_Sans({
  weight: ['400', '500', '700'],
});

const CustomText = ({ bold, customStyle, children, ...props }) => {
  return (
    <p
      className={clsx(font.className, {
        [styles.bold]: !!bold,
        [customStyle]: !!customStyle,
        [styles.text]: !customStyle,
      })}
      {...props}
    >
      {children}
    </p>
  );
};

CustomText.propTypes = {
  size: PropTypes.number,
  bold: PropTypes.bool,
  style: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  children: PropTypes.node,
  customStyle: PropTypes.string,
};

export default CustomText;
