import Logo from '@assets/icons/logo.svg';
import CustomButton from '@components/shared/CustomButton';
import Link from 'next/link';

import styles from './Navbar.module.scss';

const Navbar = () => {
  return (
    <div className={styles.navbar}>
      <Link href={`/`}>
        <Logo />
      </Link>
      <CustomButton
        text='Download the app'
        customStyle={styles.btn}
        secondary
        onClick={() => {}}
      />
    </div>
  );
};

export default Navbar;
