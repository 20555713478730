import AppShowcaseImage from '@assets/images/app-showcase.png';
import CustomText from '@components/shared/CustomText';
import GetStartedButton from '@components/shared/GetStartedButton';
import Image from 'next/image';

import styles from './AppAdvertisement.module.scss';

const AppAdvertisement = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <CustomText customStyle={styles.primaryText} bold>
          Make this process easier. Download the EverySign app!
        </CustomText>
        <CustomText customStyle={styles.secondaryText}>
          {`You'll be able to complete any kind of digital document in seconds.`}
        </CustomText>

        <GetStartedButton />
      </div>

      <div className={styles.imageContainer}>
        <div className={styles.image}>
          <Image src={AppShowcaseImage} fill alt='app showcase' />
        </div>
      </div>
    </div>
  );
};

export default AppAdvertisement;
