import Navbar from '@features/Navbar';
import useSiteConfig from '@shared/hooks/useSiteConfig';
import clsx from 'clsx';
import Head from 'next/head';
import PropTypes from 'prop-types';

import styles from './Layout.module.scss';

const Layout = ({ children, center, flex }) => {
  const config = useSiteConfig();
  return (
    <div className={styles.layout}>
      <Head>
        <title>{config.title}</title>
        <meta name='description' content={config.description} />
      </Head>
      <Navbar />
      <div
        className={clsx(styles.content, {
          [styles.center]: center,
          [styles.flex]: flex,
        })}
      >
        {children}
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  center: PropTypes.bool,
  flex: PropTypes.bool,
};

export default Layout;
