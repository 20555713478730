import ArrowRightOrange from '@assets/icons/arrow-right-orange.svg';
import styles from './GetStartedButton.module.scss';
import CustomButton from '../CustomButton';

const GetStartedButton = () => {
  return (
    <div className={styles.btnContainer}>
      <CustomButton text='Get Started' transparent customStyle={styles.btn} />
      <ArrowRightOrange />
    </div>
  );
};

export default GetStartedButton;
